import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'
import DefaultButton from '../common/Button'

const Container = styled.div`
    margin: 60px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export default function RegisterButton({label}) {
    const { appState, sendEvent } = useContext(AppContext);

    return (
        <Container>
            <Link to={"/register"} onClick={() => sendEvent("Click", "Register", window.location.pathname)}>
                <DefaultButton label={label ?? appState.i18n.all.join} />
            </Link>
        </Container>
    )
}
