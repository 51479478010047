import React, { useContext } from 'react'
import styled from "styled-components"
import { AppContext } from '../../contexts/AppContext'
import DiscountTag from './DiscountTag'

const Wrapper = styled.div`
    z-index: 10;
`

const Container = styled.div`
    height: 350px;
    width: 250px;
    background-color: #FFFFFF !important;
    -webkit-box-shadow: ${`6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff !important`};
    -moz-box-shadow: ${`6px 6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff !important`};
    box-shadow:  ${`6px 6px 11px #e3e3e3, -6px -6px 11px #ffffff !important`};
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Stripes = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px 15px 0 0;
    -moz-border-radius: 15px 15px 0 0;
    -webkit-border-radius: 15px 15px 0 0;
    overflow: hidden;
`

const Stripe1 = styled.div`
    background-color: ${props => props.tileColor};
    width: 100%;
    height: 7px;
`

const Stripe2 = styled.div`
    background-color: ${props => props.tileColor + "cc"};
    width: 100%;
    height: 7px;
`

const Title = styled.div`
    font-size: 20pt;
    margin-top: -13px;
`

const PlanName = styled.h3`
    display: inline-block;
    color: #484848;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
`

const PlanExpansion = styled.h3`
    margin-left: 3px;
    display: inline-block;
    color: ${props => props.tileColor};
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
`

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
`

const PriceWrap = styled.div`
    display: inline-block;
    position: relative;
`

const Price = styled.span`
    font-size: 23pt;
    font-weight: 600;
`

const PVP = styled.span`
    color: ${props => props.theme.default.colors.mediumGrey};
`

const Subtitle = styled.span`
    font-size: 10pt;
    font-weight: 400;
    color: ${props => props.theme.default.colors.mediumGrey};
`

const PartnerLogos = styled.div`
    width: 150px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;
`

const PartnerLogoImg = styled.img`
    max-height: 20px;
`

const DiscountTagV2 = styled(DiscountTag)`
    position: absolute !important;
    margin-top: 9px;
    margin-left: 10px;
    font-size: 10pt;
    font-weight: 500;
`


export default function PlanCard_v2({ planName, planExpansion, vazio_preco, vazio_pvp, foravazio_preco, vazio_desconto, foravazio_desconto, foravazio_pvp, tileColor, logos }) {
    const { appState } = useContext(AppContext);

    return (
        <Wrapper>
            <Container>
                <Stripes>
                    <Stripe1 tileColor={tileColor}></Stripe1>
                    <Stripe2 tileColor={tileColor}></Stripe2>
                </Stripes>
                <Title>
                    <PlanName>{planName}</PlanName>
                    <PlanExpansion tileColor={tileColor}>{planExpansion}</PlanExpansion>
                </Title>
                <PriceContainer>
                    <PriceWrap>
                        <Price>{vazio_preco}</Price>
                        {vazio_desconto && <DiscountTagV2 message={vazio_desconto}/>}
                    </PriceWrap>
                    <PVP>{vazio_pvp}</PVP>
                    <Subtitle>{appState.i18n.prices.offPeak}</Subtitle>
                </PriceContainer>
                <PriceContainer>
                    <PriceWrap>
                        <Price>{foravazio_preco}</Price>
                        {foravazio_desconto && <DiscountTagV2 message={foravazio_desconto}/>}
                    </PriceWrap>
                    <PVP>{foravazio_pvp}</PVP>
                    <Subtitle>{appState.i18n.prices.peak}</Subtitle>
                </PriceContainer>
                <PartnerLogos>
                    {
                        logos.map((img, i) => <PartnerLogoImg key={i} src={img}></PartnerLogoImg>)
                    }
                </PartnerLogos>
            </Container>
        </Wrapper>
    )
}
