import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    max-width: 600px;
    padding: 50px 0;
` 

const Title = styled.h3`
    font-weight: 600;
    font-size: 22px;
`

const Text = styled.p`
    font-size: 11pt;
    font-weight: 300;
`

export default function AboutPlanSnippet({title, text}) {
    return (
        <Container>
            <Title>{title}</Title>   
            <Text>{text}</Text>
        </Container>
    )
}
