import React from 'react'
import styled from 'styled-components'
import logo from '../../images/logo.png'

import freeCards from '../../images/perks/freecards.png'
import invoiceDiscount from '../../images/perks/discount.png'
import imediateAccess from '../../images/perks/imediateaccess.png'
import easyInvoicing from '../../images/perks/easyinvoice.png'
import adaptable from '../../images/perks/adaptable.png'
import completeSolution from '../../images/perks/completesolution.png'
import guaranteedService from '../../images/perks/guaranteedservice.png'
import customMade from '../../images/perks/custommade.png'
import reduceWaste from '../../images/perks/reducewaste.png'

const Container = styled.div`
    width: 200px;
    height: 350px;
    /* border: ${props => props.theme.default.colors.veryLightGrey}; */
    /* border-style: solid; */
    -webkit-box-shadow: 6px 11px 0 #0000001f !important;
    -moz-box-shadow: 6px 6px 11px 0 #0000001f !important;
    box-shadow:  6px 6px 11px #0000001f !important;
    border-radius: 20px;
    padding: 30px;
    margin: 20px;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
`

const Image = styled.div`
    background-image: url(${props => props.img});
    background-size: cover;
    width: 110px;
    height: 100px;
    margin-bottom: 25px;
`

const Title = styled.h3`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 500;
    font-size: 12pt;
`

const Text = styled.p`
    font-size: 14px;
`

const getImage = (id) => {
    switch (id) {
        case 'freeCards':
            return freeCards;
        case 'invoiceDiscountHome':
        case 'invoiceDiscountVolt':
            return invoiceDiscount;
        case 'imediateAccess':
            return imediateAccess;
        case 'easyInvoicing':
            return easyInvoicing;
        case 'adaptable':
            return adaptable;
        case 'completeSolution':
            return completeSolution;
        case 'guaranteedService':
            return guaranteedService;
        case 'customMade':
            return customMade;
        case 'reduceWaste':
            return reduceWaste;
        default:
            return logo;
    }
}

export default function PerkCard({ id, title, text }) {
    return (
        <Container>
            <Wrapper>
                <Image img={getImage(id)} />
                <Title>{title}</Title>
                <Text>{text}</Text>
            </Wrapper>
        </Container>
    )
}
