import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'
import AboutPlanSnippet from './AboutPlanSnippet'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 60px 10%;
`

const Title = styled.h2`
    text-transform: uppercase;
` 


export default function AboutPlanTower({page, snippets, title}) {
    const {appState} = useContext(AppContext);

    return (
        <Container>
            <Title>{title ? title : appState.i18n.aboutPlans.title}</Title>
            {snippets.map((snippet, i) => 
                <AboutPlanSnippet key={i} title={appState.i18n.aboutPlans[page][snippet.title]} text={appState.i18n.aboutPlans[page][snippet.text]}/>
            )}
        </Container>
    )
}
