import React from 'react'
import styled from "styled-components"

const Wrapper = styled.div`
    margin-top: -30px !important;
    z-index: 1;
`

const Container = styled.div`
    width: 180px;
    background-color: ${props => props.theme.default.colors.plans.conditionsBG} !important;
    -webkit-box-shadow: ${`6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff !important`};
    -moz-box-shadow: ${`6px 6px 11px 0 #e3e3e3, -6px -6px 11px 0 #ffffff !important`};
    box-shadow:  ${`6px 6px 11px #e3e3e3, -6px -6px 11px #ffffff !important`};
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px 20px 20px;
`

const Condition = styled.h5`
    color: ${props => props.tileColor};
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    font-size: 9pt;
    font-weight: 400;
    text-align: center;
`

export default function PlanConditionsTile({ conditions }) {
    return (
        <Wrapper>
            <Container>
                {conditions.map((condition, i) => <Condition key={i}>{condition}</Condition>) }
            </Container>
        </Wrapper>
    )
}
