import React, { useContext } from "react"
import styled, { withTheme } from "styled-components"
import Layout from "../../../components/layout"
import Footer from "../../../components/layout/Footer"
import PlanCard_v2 from "../../../components/plans/PlanCard_v2"
import SEO from "../../../components/seo"
import evazLogo from "../../../images/logo.png"
import exameInfLogo from "../../../images/partners/exameinformatica_logo.png"
import alfaLogo from "../../../images/partners/alfa-energia.png"
import HeroContainer from "../../../components/layout/HeroContainer"
import { AppContext } from "../../../contexts/AppContext"
import PlanConditionsTile from "../../../components/plans/PlanConditionsTile"
import AboutPlanTower from "../../../components/layout/AboutPlanTower"
import { Link } from "gatsby"
import SimilarOffers from "../../../components/layout/SimilarOffers"
import Offer from "../../../components/layout/Offer"
import RegisterButton from "../../../components/layout/RegisterButton"
import CardsDisplayer from "../../../components/layout/CardsDisplayer"
import DotDivider from "../../../components/layout/DotDivider"
import PricesTable from "../../../utils/Prices"
import WarningBox from "../../../components/layout/WarningBox"

const Wrapper = styled.div`
  padding-top: 70px;
  margin: 50px 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const RegButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

const TilesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    
    @media (max-width: 1025px) {
      flex-direction: column;
      align-items: center;
    }
`

const TileAndConditions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    transition: 0.3s;

    @media (max-width: 1025px) {
        margin-bottom: 50px;
    }

    :hover {
      margin-top: 5px;
    }
`

const InfoText = styled.div`
  color: ${props => props.theme.default.colors.mediumGrey};
  margin-bottom: 20px;
  font-size: 10pt;
`

const snippets = [{
  title: "Text1_Title",
  text: "Text1"
},
{
  title: "howItWorks_Title",
  text: "howItWorks"
},
{
  title: "evazRole_Title",
  text: "evazRole"
},]

const mobilityPlan_Cards = [{ key: "freeCards" }, { key: "imediateAccess" }, { key: "easyInvoicing" }]


const PlanComparison = ({ theme }) => {
  const { appState, sendEvent } = useContext(AppContext);
  const conditions = appState.i18n.plansConditions
  
  return <Layout>
    <SEO title="MOVE - Planos Mobilidade" />
    <HeroContainer title={appState.i18n.header.allPlans} subtitle={appState.i18n.heroContainers.allPlansText} id="mobility" />
    <Wrapper>

      <InfoText>{appState.i18n.plans.allPlans_clickTiles}</InfoText>
      <TilesContainer>
        <Link to={"/solutions/plans/mobility"} onClick={() => sendEvent("Plan Tile", "Move Base", "From Plans Comparison")}>
          <TileAndConditions>
            <PlanCard_v2
              planName={"MOVE"}
              planExpansion={"BASE"}
              vazio_preco={`€${PricesTable.mobility.base.offPeak.price}`}
              vazio_pvp={`€${PricesTable.mobility.base.offPeak.pvp}*`}
              foravazio_preco={`€${PricesTable.mobility.base.peak.price}`}
              foravazio_pvp={`€${PricesTable.mobility.base.peak.pvp}*`}
              vazio_desconto={""}
              foravazio_desconto={""}
              tileColor={theme.default.colors.plans.move}
              logos={[evazLogo]} />
            <PlanConditionsTile conditions={[conditions.base.condition1, conditions.base.condition2, conditions.base.condition3, conditions.base.condition4, conditions.base.condition5, conditions.base.condition9]} />
          </TileAndConditions>
        </Link>
        <Link to={"/solutions/plans/residential"} onClick={() => sendEvent("Plan Tile", "Move Home", "From Plans Comparison")}>
          <TileAndConditions>
            <PlanCard_v2
              planName={"MOVE"}
              planExpansion={"HOME"}
              vazio_preco={`€${PricesTable.mobility.home.offPeak.priceWithDiscount}`}
              vazio_pvp={`€${PricesTable.mobility.home.offPeak.pvpWithDiscount}*`}
              foravazio_preco={`€${PricesTable.mobility.home.peak.priceWithDiscount}`}
              foravazio_pvp={`€${PricesTable.mobility.home.peak.pvpWithDiscount}*`}
              vazio_desconto={PricesTable.mobility.home.offPeak.discountOnPrice}
              foravazio_desconto={PricesTable.mobility.home.peak.discountOnPrice}
              tileColor={theme.default.colors.plans.home}
              logos={[evazLogo, alfaLogo]} />
            <PlanConditionsTile conditions={[conditions.home.condition1, conditions.home.condition2, conditions.home.condition3, conditions.home.condition4, conditions.home.condition5, conditions.home.condition6, conditions.home.condition7, conditions.home.condition8, conditions.base.condition9]} />
          </TileAndConditions>
        </Link>
      </TilesContainer>
      <InfoText>{appState.i18n.plansConditions.tarifa_EGME_discount}</InfoText>
      <RegButtonContainer>
        <RegisterButton label={appState.i18n.get_card} />
      </RegButtonContainer>
      <WarningBox />
      <DotDivider />
      <AboutPlanTower page={"all"} snippets={snippets} />
      <CardsDisplayer cards={mobilityPlan_Cards} />
      <RegButtonContainer>
        <RegisterButton label={appState.i18n.get_card} />
      </RegButtonContainer>
      <DotDivider />
      <SimilarOffers title={appState.i18n.similarOffers.title}>
        <Offer link={"/solutions/private/powerpack"} title={appState.i18n.similarOffers.powerpacksTitle} text={appState.i18n.similarOffers.powerpacksText} />
        <Offer link={"/solutions/plans/residential"} title={appState.i18n.similarOffers.residentialPlanTitle} text={appState.i18n.similarOffers.residentialPlanText} />
      </SimilarOffers>
    </Wrapper>
    <footer>
      <Footer />
    </footer>
  </Layout>
}

export default withTheme(PlanComparison)
