import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext';
import PerkCard from './PerkCard'
import styled from 'styled-components';

const Container = styled.div`
    margin: 60px 0;
`

const ImageDisplayer = styled.div`
    display: flex;
    justify-content: space-around;
    max-width: 1400px;
    margin: 0 auto;

    @media(max-width: 1000px) {
        flex-wrap: wrap;
    }
`

export default function CardsDisplayer({cards}) {
    const { appState, sendEvent } = useContext(AppContext);

    return (
        <Container>
            <ImageDisplayer>
                {cards.map(card => <PerkCard
                    key={card.key}
                    id={card.key}
                    title={appState.i18n.perksCards[card.key].title} 
                    text={appState.i18n.perksCards[card.key].text} 
                />)}
            </ImageDisplayer>
        </Container>
    )
}
